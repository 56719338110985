export async function fetchTaxonomy() {
  try {
    const data = await $fetch('/api/get-kontent-items/taxonomy')
    return data
  } catch (error) {
    console.error('Error fetching taxonomy:', error)
    return null
  }
}

export async function fetchPeople() {
  try {
    const data = await $fetch('/api/get-kontent-items/people')
    return data
  } catch (error) {
    console.error('Error fetching people:', error)
    return null
  }
}

export async function fetchLocations() {
  try {
    const data = await $fetch('/api/get-kontent-items/locations')
    return data
  } catch (error) {
    console.error('Error fetching locations:', error)
    return null
  }
}
